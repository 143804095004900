//scss-lint:disable all
@import 'partials/variables';

body {
    background-color: $beige;
}

.login {
    h1 a {
        background-color: $white;
        background-image: url('../images/logo/fao-cause-connector.svg');
        background-position: center center;
        background-size: 250px auto;
        border: 1px solid #ccd0d4;
        border-radius: 0.25rem;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.04);
        height: 60px;
        padding: 24px;
        width: 270px;
    }

    form {
        border-radius: 0.25rem;
    }
}
