/* ======================================
    Colors
====================================== */

//Neutrals
$white: #fff;
$gray-xlight: #efefef;
$gray-light: #9a989a;
$gray: #585a5c;
$gray-dark: #242224;
$black: #000;

$gray-warm-dark: #80746b;

// Primary
$orange: #f38a00;
$orange-med: #ed7800;
$orange-dark: #dc5e02;
$purple: #2d1e4e;

// Accents
$red: #ca4646;
$pink: #c3497b;
$salmon: #dd6a6a;
$green: #388b33;
$yellow: #c09f21;
$purple-light: #5d488a;
$blue-light: #6cb8d7;

// Browns
$brown: #534137;
$brown-light: #62564f;
$beige: #dbd6d4;


/* ======================================
    Fonts, weights
====================================== */

$sans: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$century: 'century-gothic', sans-serif;

$normal: 400;
$bold: 700;


/* ======================================
    Breakpoints
====================================== */

$breakpoints: (
    'sm-mobile': 20em,        // 320px
    'mobile': 23.4375em,      // 375px
    'lg-mobile': 25em,        // 400px
    'phablet': 37.5em,        // 600px
    'sm-tablet': 46em,        // 736px
    'tablet': 47.9375em,      // 767px
    'tablet-vert': 48em,      // 768px
    'lg-tablet': 50em,        // 800px
    'xs-desktop': 57.5em,     // 920px
    'sm-desktop': 62.5em,     // 1000px
    'tablet-horiz': 64em,     // 1024px
    'desktop': 75em,          // 1200px
    'md-desktop': 87.5em,     // 1400px
    'lg-desktop': 100em       // 1600px
);
